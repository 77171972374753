import { Link } from '@remix-run/react';
import { cn } from '@cardo/lib';

import logo from '../../../images/logo-wide.svg';
import { useState } from 'react';
import LayoutPaddingX from '../LayoutPaddingX';
import AdvertiserDisclosure from '../../AdvertiserDisclosure';
import CoreSearchBox from '../../search/CoreSearchBox';
import NavItem from './NavItem';
import MobileNavItem from './MobileNavItem';
import NavItemDropdown from './NavItemDropdown';
import MobileNavItemDropdown from './MobileNavItemDropdown';
import { Button } from '@cardo/ui';
import { useOptionalUser } from '~/lib/utils';

type MainNavProps = {
  className?: string;
};

export default function MainNav({ className }: MainNavProps) {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const user = useOptionalUser();

  const authenticatedAppUrl =
    typeof window !== 'undefined'
      ? window.ENV.AUTHENTICATED_APP_URL
      : process.env.AUTHENTICATED_APP_URL;

  return (
    <>
      <LayoutPaddingX className="shadow-md">
        <header className="hidden flex-col py-7 lg:flex">
          <nav className="flex items-center justify-between gap-6">
            <div
              className="flex flex-shrink-0"
              style={{
                height: '35px',
                width: '252px',
              }}
            >
              <Link to="/">
                <img
                  src={logo}
                  alt="cardonomics logo"
                  width={252}
                  height={35}
                />
              </Link>
            </div>
            <div className={cn('flex items-center gap-6 xl:gap-8', className)}>
              <div className="flex items-center gap-4 xl:gap-10">
                {/* <NavItemDropdown to="/cardpicker" title="Card Picker" /> */}
                <NavItemDropdown
                  to="/issuers/american-express"
                  title="Card Issuers"
                >
                  <NavItem
                    to="/issuers/american-express"
                    title="American Express"
                  />
                  <NavItem to="/issuers/capital-one" title="Capital One" />
                  <NavItem to="/issuers/chase" title="Chase" />
                </NavItemDropdown>
                <NavItemDropdown to="/cards/best-cards" title="Credit Cards">
                  <NavItem to="/cards/best-cards" title="Best Overall" />
                  <NavItem to="/cards/travel" title="Travel" />
                  <NavItem to="/cards/cash-back" title="Cash back" />
                  <NavItem to="/cards/business" title="Business" />
                  <NavItem
                    to="/cards/building-credit"
                    title="Building credit"
                  />
                  <NavItem
                    to="/cards/balance-transfer"
                    title="Balance transfer"
                  />
                </NavItemDropdown>
                <NavItemDropdown
                  to="/articles/tag/credit-cards"
                  title="Articles"
                />
              </div>
              <CoreSearchBox />
              <AdvertiserDisclosure className="relative hidden md:flex" />
              <div className="w-px h-8 bg-theme-blue-darkest" />
              <div className="">
                {user && (
                  <Link
                    to={`${authenticatedAppUrl}`}
                    className="text-theme-blue-darkest"
                  >
                    <Button primary large className="bg-[#4F46E5]">
                      App
                    </Button>
                  </Link>
                )}
                {!user && (
                  <>
                    <Link
                      to={`${authenticatedAppUrl}/login`}
                      className="text-theme-blue-darkest"
                    >
                      <Button ghost large>
                        Log In
                      </Button>
                    </Link>
                    <Link
                      to={`${authenticatedAppUrl}/signup`}
                      className="text-theme-blue-darkest"
                    >
                      <Button primary large className="bg-[#4F46E5]">
                        Sign Up
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </nav>
        </header>
      </LayoutPaddingX>
      <header className="fixed z-[1000] flex h-auto w-full lg:hidden">
        <nav className="w-full">
          <div className="flex w-full flex-col space-y-2 bg-white px-4 py-4 shadow">
            <div className="relative flex w-full items-center justify-between">
              <div>
                <Link to="/" onMouseDown={() => setMobileNavOpen(false)}>
                  <img
                    src={logo}
                    alt="cardonomics logo"
                    width={252}
                    height={35}
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <button
                  className="flex w-8 flex-col space-y-1.5 overflow-hidden outline-none"
                  onClick={() => setMobileNavOpen((prev) => !prev)}
                >
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'translate-x-full' : ''
                    }`}
                  ></span>
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'mx-auto rotate-45' : ''
                    }`}
                  ></span>
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'mx-auto -translate-y-2.5 -rotate-45' : ''
                    }`}
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${
              mobileNavOpen
                ? 'h-screen min-h-fit overflow-auto'
                : 'h-0 overflow-hidden'
            } transition-height z-40 bg-white duration-300 ease-in-out`}
          >
            <ul
              role="menu"
              className={cn('mb-24 flex flex-col p-5', className)}
            >
              <CoreSearchBox className="mb-3" />
              {/* <li>
                <MobileNavItemDropdown
                  to="/cardpicker"
                  title="Card Picker"
                  close={() => setMobileNavOpen(false)}
                />
              </li> */}
              <li>
                <MobileNavItemDropdown
                  to="/issuers/american-express"
                  title="Card Issuers"
                  close={() => setMobileNavOpen(false)}
                >
                  <MobileNavItem
                    to="/issuers/american-express"
                    title="American Express"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/issuers/capital-one"
                    title="Capital One"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/issuers/chase"
                    title="Chase"
                    close={() => setMobileNavOpen(false)}
                  />
                </MobileNavItemDropdown>
              </li>
              <li>
                <MobileNavItemDropdown
                  to="/cards/best-cards"
                  title="Credit Cards"
                  close={() => setMobileNavOpen(false)}
                >
                  <MobileNavItem
                    to="/cards/best-cards"
                    title="Best Overall"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/cards/travel"
                    title="Travel"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/cards/cash-back"
                    title="Cash back"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/cards/business"
                    title="Business"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/cards/building-credit"
                    title="Building credit"
                    close={() => setMobileNavOpen(false)}
                  />
                  <MobileNavItem
                    to="/cards/balance-transfer"
                    title="Balance transfer"
                    close={() => setMobileNavOpen(false)}
                  />
                </MobileNavItemDropdown>
              </li>
              <li>
                <MobileNavItemDropdown
                  to="/articles/tag/credit-cards"
                  title="Articles"
                  close={() => setMobileNavOpen(false)}
                />
              </li>
              <AdvertiserDisclosure className="mt-4 text-lg font-bold" />
            </ul>
          </div>
        </nav>
      </header>
      <div className="h-[67px] lg:hidden"></div>
    </>
  );
}
